<template>
  <div class="flex">
    <GroupList />
    <router-view />
  </div>
</template>

<script>
import GroupList from "@/components/GroupList";

export default {
  name: "Home",
  components: { GroupList },
  computed: {
    showSidebar() {
      return this.$store.state.showSidebar;
    },
  },
};
</script>
