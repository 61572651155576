<template>
  <div>
    <input
      v-model="filterTerm"
      class="bg-white py-2 px-3 w-full outline-none focus:outline-none bg-gray-100 hover:bg-gray-200 focus:bg-yellow-100 transition-all duration-200 rounded text-xs"
      :placeholder="placeholder || 'Filter'"
    />
    <span
      v-if="stateFilter"
      class="reset absolute right-0 top-0 h-full flex items-center mr-1"
    >
      <XIcon @click="filterTerm = null" class="cursor-pointer" />
    </span>
  </div>
</template>

<script>
import { XIcon } from "@vue-hero-icons/outline";
import { debounce } from "lodash";
export default {
  name: "FilterInput",
  props: ["placeholder", "action"],
  data() {
    return {
      filterTerm: "",
    };
  },
  computed: {
    stateFilter() {
      if (this.$props.action === "setGroupFilter") {
        return this.$store.state.filterGroups;
      } else {
        return this.$store.state.filterLabels;
      }
    },
  },
  methods: {
    setFilter: debounce(function () {
      this.$store.dispatch(this.$props.action, this.filterTerm);
    }, 500),
  },
  watch: {
    // stateFilter() {
    //   this.filterTerm = this.$store.state.filterTerm;
    // },
    filterTerm() {
      if (this.filterTerm === null) {
        this.$store.dispatch(this.$props.action, "");
      } else {
        this.setFilter();
      }
    },
  },
  components: {
    XIcon,
  },
};
</script>
