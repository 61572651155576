<template>
  <div class="relative">
    <input
      v-model="filterTerm"
      class="bg-white py-2 px-3 w-full outline-none focus:outline-none bg-gray-100 hover:bg-gray-200 focus:bg-yellow-100 transition-all duration-200 rounded text-xs"
      :placeholder="placeholder || 'Filter'"
    />
    <span
      v-if="filterTerm"
      class="reset absolute right-0 top-0 h-full flex items-center"
    >
      <XIcon @click="$emit('filter', false)" class="cursor-pointer mr-3" />
    </span>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import { XIcon } from "@vue-hero-icons/outline";
export default {
  name: "FilterInputLocal",
  props: ["placeholder", "items", "keys"],
  data() {
    return {
      filterTerm: "",
    };
  },
  watch: {
    filterTerm() {
      if (this.filterTerm) {
        const fuse = new Fuse(this.$props.items, {
          threshold: 0.2,
          keys: this.$props.keys,
        });

        this.$emit(
          "filter",
          fuse.search(this.filterTerm).map((x) => x.item),
        );
      } else {
        this.$emit("filter", false);
      }
    },
  },
  components: {
    XIcon,
  },
};
</script>
