<template>
  <span :class="classes" :style="style">
    <span v-if="showText">{{ count }}</span>
    <span v-else></span>
  </span>
</template>

<script>
export default {
  name: "StatusBar",
  props: ["color", "count", "label", "total", "showText"],
  computed: {
    classes() {
      let classes =
        "whitespace-nowrap text-xs flex items-center justify-center font-bold px-4 py-1";
      if (this.$props.count === 0) {
        classes = `${classes} opacity-30`;
      }

      if (this.$props.color === "red") {
        classes = classes + " bg-red-100 text-red-900";
      } else if (this.$props.color === "green") {
        classes = classes + " bg-green-100 text-green-900";
      } else if (this.$props.color === "yellow") {
        classes = classes + " bg-yellow-100 text-yellow-900";
      } else if (this.$props.color === "gray") {
        classes = classes + " bg-gray-100 text-gray-900";
      }
      return classes;
    },
    style() {
      return {
        minWidth: `calc(${(this.$props.count / this.$props.total) * 100}%)`,
      };
    },
  },
};
</script>
