<template>
  <div class="w-screen h-screen flex flex-col overflow-hidden">
    <div
      v-if="nav === 'Standard'"
      id="nav"
      class="bg-white flex items-center justify-between"
    >
      <router-link to="/" class="font-bold w-1/3">
        <span v-if="$route.name === 'Home'">MoMA Wall Label Generator</span>
        <span v-else>Home</span>
      </router-link>

      <div v-if="!$auth.loading" class="text-sm w-1/3 flex justify-end">
        <!-- show login when not authenticated -->
        <button v-if="!$auth.isAuthenticated" @click="login" class="button">
          Log in
        </button>
        <!-- show logout when authenticated -->
        <div v-if="$auth.isAuthenticated" class="flex items-center space-x-3">
          <router-link to="/search" class="button text-sm">Search</router-link>
          <button @click="logout" class="button">
            Logout ({{ $auth.user.name }})
          </button>
        </div>
      </div>
    </div>

    <transition name="fade">
      <router-view
        v-if="$auth.isAuthenticated"
        id="content"
        class="overflow-y-scroll w-full h-full"
      />
    </transition>
  </div>
</template>

<script>
import Mark from "mark.js";

export default {
  name: "App",
  data() {
    return {
      marker: new Mark(".markable"),
    };
  },

  computed: {
    ready() {
      return !this.$auth.loading && this.$auth.isAuthenticated;
    },
    searchTerm() {
      return this.$store.state.searchTerm;
    },
    filterTerm() {
      return this.$store.state.filterTerm;
    },
    nav() {
      return this.$route.meta.nav;
    },
    zoomedOut() {
      return this.$store.state.zoomedOut;
    },
    group() {
      return this.$store.state.currentGroup;
    },
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
  watch: {
    filterTerm() {
      if (this.filterTerm) {
        this.marker.unmark();
        this.marker.mark(this.filterTerm);
      } else {
        this.marker.unmark();
      }
    },
    $route() {
      this.$store.dispatch("setFilterTerm", "");
      this.$store.commit("FLUSH_SELECTED");
    },
  },
};
</script>

<style lang="css">
#nav {
  @apply bg-white p-3 border-b border-black;
  /* background: rgb(243, 244, 246);
  background: linear-gradient(
    0deg,
    rgba(243, 244, 246, 0) 0%,
    rgba(243, 244, 246, 1) 45%
  ); */
}
</style>
