import { getInstance } from "./index";

export const authenticationGuard = (to, from, next) => {
  const authService = getInstance();

  const guardAction = () => {
    if (authService.isAuthenticated) {
      return next();
    }

    authService.loginWithRedirect();
  };

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.loading) {
    return guardAction();
  }

  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return guardAction();
    }
  });
};
