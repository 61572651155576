<template>
  <span :class="classes">{{ count }} {{ label }}</span>
</template>

<script>
export default {
  name: "Badge",
  props: ["color", "count", "label"],
  computed: {
    classes() {
      let classes = `rounded text-xs flex items-center justify-center font-bold px-4 py-1 bg-${this.$props.color}-100 text-${this.$props.color}-900`;
      if (this.$props.count === 0) {
        classes = `${classes} opacity-30`;
      }
      return classes;
    },
  },
};
</script>
