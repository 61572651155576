<template>
  <li
    class="w-full relative"
    :class="{
      'bg-gray-300 transform scale-105': focused && !moveGroup,
      'hover:bg-gray-50': !focused && !moveGroup && !invalidMoveTarget,
      'cursor-not-allowed': invalidMoveTarget,
    }"
    @click="handleClick"
  >
    <div
      class="flex items-center justify-between"
      :class="{
        'bg-blue-100': isCurrentlySelected,
        'hover:bg-gray-200':
          !isCurrentlySelected && !isMoveTarget && !invalidMoveTarget,
        'bg-red-100': moveTarget && moveTarget._id === group._id,
      }"
    >
      <div class="flex items-center justify-between px-2 w-full">
        <div
          class="w-4 h-4 space-x-2 flex flex-shrink-0 items-center justify-center rounded hover:bg-gray-300 mr-1 opacity-0 cursor-pointer"
          :class="{ 'opacity-100': hasChildren }"
          @click.stop="showSubGroups = !showSubGroups"
        >
          <img
            v-if="!showSubGroups"
            src="../../src/assets/folder-chevron-right.svg"
          />
          <img v-else src="../../src/assets/folder-chevron-down.svg" />
        </div>
        <router-link
          class="block py-1 flex-grow truncate mr-6"
          v-if="link"
          :to="`/group/${group.id}/${getSlug(group.name)}`"
          @click.native="showSubGroups = true"
        >
          <h2 class="markable text-sm group-hover:text-bold truncate truncate">
            {{ group.name }}
          </h2>
        </router-link>
        <h2 v-else class="markable text-sm group-hover:text-bold truncate">
          {{ group.name }}
        </h2>
        <div
          v-if="hasMenu"
          class="relative group ml-auto justify-self-end flex-shrink-0"
        >
          <div class="flex space-x-1 px-1 py-2">
            <span class="rounded-full h-1 w-1 bg-gray-400" />
            <span class="rounded-full h-1 w-1 bg-gray-400" />
            <span class="rounded-full h-1 w-1 bg-gray-400" />
          </div>
          <ul
            class="absolute bg-white text-right right-0 top-0 hidden group-hover:block border border-gray rounded cursor-pointer divide-y text-sm -mt-3 shadow-lg z-20 w-36"
          >
            <li
              class="px-3 py-2 font-normal hover:font-bold whitespace-nowrap flex items-center justify-between"
              @click.prevent="addSubfolder(group)"
            >
              <img
                src="../../src/assets/icon-create.svg"
                alt="create"
                class="h-6 w-6"
              />
              Add Folder
            </li>
            <li
              class="px-3 py-2 font-normal hover:font-bold whitespace-nowrap flex items-center justify-between"
              @click.prevent="renameGroup(group)"
            >
              <img src="../../src/assets/icon-rename.svg" alt="rename" />
              Rename
            </li>
            <li
              class="px-3 py-2 font-normal hover:font-bold whitespace-nowrap flex items-center justify-between"
              @click.prevent="startMove(group)"
            >
              <img src="../../src/assets/icon-move.svg" alt="move" />
              Move
            </li>
            <li
              class="px-3 py-2 hover:text-red-500 font-normal hover:font-bold whitespace-nowrap flex items-center justify-between"
              @click.prevent="deleteGroup(group)"
            >
              <img src="../../src/assets/icon-delete.svg" alt="delete" />
              Delete
            </li>
          </ul>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="showCount"
        class="flex absolute top-0 left-0 right-0 bottom-0 mix-blend-multiply pointer-events-none"
      >
        <BaseStatusBar
          color="red"
          v-if="group.numLabelsDraft"
          :count="group.numLabelsDraft"
          label="Draft"
          :showText="false"
          :total="group.numLabels"
        />
        <BaseStatusBar
          color="yellow"
          v-if="group.numLabelsEditorial"
          :count="group.numLabelsEditorial"
          label="Editorial"
          :showText="false"
          :total="group.numLabels"
        />
        <BaseStatusBar
          color="green"
          v-if="group.numLabelsProduction"
          :count="group.numLabelsProduction"
          label="Production"
          :showText="false"
          :total="group.numLabels"
        />
        <BaseStatusBar
          color="gray"
          :count="group.numLabelsArchived"
          label="Archived"
          :showText="false"
          :total="group.numLabels"
        />
      </div>
    </transition>
    <div
      v-show="showSubGroups && hasChildren"
      class="border-l border-black ml-4"
    >
      <SubGroup
        v-for="group in subGroups"
        :key="group.id"
        :group="group"
        :link="link"
        :hasMenu="hasMenu"
        :showCount="showCount"
        @click.native.stop="$emit('clicked', group)"
        @open="onOpen"
      />
    </div>
  </li>
</template>

<script>
import { mapState } from "vuex";

import ModalRenameGroup from "@/components/Modals/ModalRenameGroup";
import ModalDeleteGroup from "@/components/Modals/ModalDeleteGroup";
import ModalMoveGroup from "@/components/Modals/ModalMoveGroup";

export default {
  name: "group-block",
  props: ["group", "showCount", "hasMenu", "link"],
  data() {
    return {
      focused: false,
      showSubGroups: false,
    };
  },
  components: {
    SubGroup: () => import("./Group.vue"),
  },
  methods: {
    addSubfolder(group) {
      this.$modal.show(
        () => import("./Modals/ModalAddSubGroup"),
        { group: group },
        { height: "auto" },
        { "before-open": this.beforeOpen, "before-close": this.beforeClose },
      );
    },
    renameGroup(group) {
      this.$modal.show(
        ModalRenameGroup,
        { group: group },
        { height: "auto" },
        { "before-open": this.beforeOpen, "before-close": this.beforeClose },
      );
    },
    deleteGroup(group) {
      this.$modal.show(
        ModalDeleteGroup,
        { group: group },
        { height: "auto" },
        { "before-open": this.beforeOpen, "before-close": this.beforeClose },
      );
    },
    startMove(group) {
      this.$store.commit("SET_MOVE_GROUP", group);
      this.$modal.show(
        ModalMoveGroup,
        { groupToMove: group },
        { height: "auto" },
        { "before-open": this.beforeOpen, "before-close": this.beforeClose },
      );
    },
    beforeOpen() {
      this.focused = true;
    },
    beforeClose() {
      this.$store.dispatch("stopMove");
      this.focused = false;
    },
    openParent() {
      if (this.$props.group.parent) {
        this.$emit("open", this.$props.group.parent);
      }
    },
    onOpen(id) {
      if (id === this.$props.group._id) {
        this.showSubGroups = true;
        this.openParent();
      }
    },
    handleClick() {
      this.$nextTick(() => {
        if (this.moveGroup && !this.invalidMoveTarget) {
          this.$store.commit("SET_MOVE_TARGET", this.$props.group);
        }
      });
    },
    checkOpen() {
      // Check if this group is the current route.
      // If so, and it has parent, try to open the parent
      if (this.isCurrentlySelected) {
        this.showSubGroups = true;
        this.openParent(this.$props.group.parent);
      }

      // If it is a moving situation, open the parents
      if (this.moveGroup) {
        this.openParent(this.moveGroup.parent);
      }
    },
  },
  computed: {
    ...mapState(["moveGroup", "moveTarget"]),
    invalidMoveTarget() {
      return (
        this.isCurrentlySelected ||
        (this.moveGroup && this.moveGroup.parent === this.$props.group._id)
      );
    },
    isCurrentlySelected() {
      if (this.moveGroup) {
        return this.moveGroup._id === this.$props.group._id;
      } else {
        return this.$route.params.groupId === this.$props.group._id;
      }
    },
    isMoveTarget() {
      if (this.moveTarget) {
        return this.moveTarget._id === this.$props.group._id;
      } else {
        return false;
      }
    },
    hasChildren() {
      return this.$store.getters.groupChildren(this.$props.group._id).length;
    },
    subGroups() {
      return this.$store.getters.groupChildren(this.$props.group._id);
    },
    selectedGroup() {
      return this.$store.getters.group(this.$route.params.groupId);
    },
  },
  mounted() {
    this.checkOpen();
  },
  watch: {
    $route() {
      this.checkOpen();
    },
  },
};
</script>
