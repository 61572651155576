<template>
  <div class="modal-content">
    <h2 class="font-bold">Rename <span v-text="$attrs.group.name"></span></h2>
    <form id="addGroup" @submit.prevent="submit">
      <input
        ref="input"
        v-model="name"
        name="name"
        type="text"
        class="p-2 border-gray-400 border w-full"
        required
      />
      <div class="flex justify-between space-x-4">
        <button type="button" @click="$emit('close')">Cancel</button>
        <button type="submit">Rename</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ModalRenameGroup",
  data() {
    return {
      name: this.$attrs.group.name,
      submitting: true,
    };
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    submit() {
      this.$store
        .dispatch("renameGroup", {
          id: this.$attrs.group.id,
          payload: {
            name: this.name,
          },
        })
        .then((data) => {
          if (data) {
            this.$emit("close");
          }
          this.$router.replace({
            name: "Group",
            params: { groupId: data.id, slug: this.getSlug(data.name) },
          });
        });
    },
  },
};
</script>
