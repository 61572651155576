<template>
  <div
    id="group-list"
    class="h-full border-r border-black bg-white relative flex flex-col sticky top-0"
    :class="{ closed: !showSidebar }"
  >
    <div class="flex flex-col p-3 space-y-3 shadow">
      <FilterInput
        class="relative"
        placeholder="Filter Folders"
        action="setGroupFilter"
      />
    </div>
    <ul
      v-if="groups"
      id="group-scroller"
      class="overflow-y-scroll h-full overflow-x-hidden pb-4"
    >
      <Group
        :id="`group-${group.id}`"
        v-for="group in groups"
        :group="group"
        :key="group.id"
        :showCount="showCount"
        :hasMenu="true"
        :link="true"
      ></Group>
      <li
        @click="$store.dispatch('setGroupFilter', '')"
        v-if="groups.length === 0"
        class="rounded-lg p-6 bg-white transition-all duration-300 shadow-sm hover:shadow-lg cursor-pointer flex justify-between"
      >
        <div>
          Nothing found for
          <span class="italic">{{ this.$store.state.filterTerm }}</span
          >.
        </div>
        <div>Click to reset the filter</div>
      </li>
    </ul>
    <BaseLoading v-else />

    <div
      v-show="showSidebar"
      class="text-center flex justify-center items-center bg-white w-full space-x-3 p-3 border-t border-gray-400"
    >
      <button @click="addGroup" class="button text-sm w-full">
        Add Folder
      </button>
      <button class="button text-sm w-full">
        <span v-if="!showCount" @click="showCount = true">Show Status</span>
        <span v-else @click="showCount = false">Hide Status</span>
      </button>
    </div>
  </div>
</template>

<script>
import Group from "@/components/Group";
import FilterInput from "@/components/FilterInput";
import ModalAddGroup from "@/components/Modals/ModalAddGroup";

export default {
  name: "GroupList",
  data() {
    return {
      modal: false,
      showFilter: false,
      showCount: false,
    };
  },
  components: { FilterInput, Group },
  computed: {
    groups() {
      return this.$store.getters.groups;
    },
    showSidebar() {
      return this.$store.state.showSidebar;
    },
  },
  methods: {
    addGroup() {
      this.$modal.show(ModalAddGroup, {}, { height: "auto" });
    },
    scrollToSelected() {
      if (this.$route.params.groupId) {
        this.$scrollTo(`#group-${this.$route.params.groupId}`, 650, {
          easing: "ease-out",
          container: "#group-scroller",
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch("fetchGroups").then(() => {
      this.scrollToSelected();
    });
  },
  watch: {
    $route(to) {
      if (to.meta.forceSidebar) {
        this.$store.commit("SET_SHOW_SIDEBAR", true);
      }
    },
  },
};
</script>

<style lang="css" scoped>
#group-list {
  flex: 1 0 20%;
  min-width: 24em;
  max-width: 24em;
  transition: all 0.3s;

  a.router-link-exact-active {
    @apply font-bold;
  }

  &.closed {
    min-width: 0;
    flex: 0 1 0;
  }
}
</style>
