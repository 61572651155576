import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { authenticationGuard } from "@/auth/authenticationGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      nav: "Standard",
      forceSidebar: true,
    },
    children: [
      {
        path: "/group/:groupId/:slug?",
        name: "Group",
        component: () =>
          import(/* webpackChunkName: "Group" */ "../views/Group.vue"),
        meta: {
          nav: "Standard",
        },
      },
    ],
  },
  {
    path: "/search/:term?",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "Search" */ "../views/Search.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(authenticationGuard);

export default router;
