<template>
  <div class="modal-content">
    <h2 class="font-bold">Add New Folder</h2>
    <form id="addGroup" @submit.prevent="submit">
      <input
        v-model="name"
        name="name"
        ref="input"
        type="text"
        placeholder="Enter folder name..."
        class="p-2 border-gray-400 border w-full"
        required
      />
      <div class="flex justify-between space-x-4">
        <button type="button" @click="$emit('close')">Cancel</button>
        <button type="submit">Add</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ModalAddGroup",
  data() {
    return {
      name: "",
      submitting: true,
    };
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    async submit() {
      this.$store
        .dispatch("addGroup", {
          name: this.name,
        })
        .then((data) => {
          if (data) {
            this.$emit("close");
            this.$router.push({
              name: "Group",
              params: { groupId: data.id, slug: this.getSlug(data.name) },
            });

            this.$nextTick(() => {
              this.$scrollTo(`#group-${this.$route.params.groupId}`, 650, {
                easing: "ease-out",
                container: "#group-scroller",
              });
            });
          }
        });
    },
  },
};
</script>
