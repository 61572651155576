import Vue from "vue";
import VueGtag from "vue-gtag";
import VModal from "vue-js-modal";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScrollTo from "vue-scrollto";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import slugify from "slugify";
import { format as timeAgoFormat } from "timeago.js";
import { format } from "date-fns";

import "./assets/tailwind.css";
import "./assets/fonts.css";
import "./assets/transitions.css";
import "./assets/index.css";

Vue.use(VueScrollTo);

// Import the Auth0 configuration
import { domain, clientId } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(VModal);

// Vue gtag
Vue.use(
  VueGtag,
  {
    config: { id: "UA-159378650-1" },
  },
  router
);

Vue.config.productionTip = false;

const requireComponent = require.context(
  // The relative path of the components folder
  "./components",
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

Vue.mixin({
  methods: {
    formatTimestamp: (timestamp) => {
      return format(timestamp, "Pp");
    },
    getSlug: (text) => {
      return slugify(text);
    },
    getTimeAgo: (timestamp) => {
      return timeAgoFormat(timestamp);
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
