<template>
  <div class="modal-content">
    <div class="flex items-center justify-between">
      <h2 class="font-bold">Move {{ groupToMove.name }}</h2>
      <FilterInputLocal
        ref="input"
        :items="groups"
        :keys="['id', 'name']"
        @filter="filter"
      />
    </div>
    <div v-if="moveTarget" class="mt-0 pt-0">↳ {{ moveTarget.name }}</div>
    <form id="moveGroup" @submit.prevent="submit">
      <ul class="folder-scroller border border-gray-300" v-if="groups">
        <MoveGroup
          v-for="group in groups"
          :group="group"
          :key="group.id"
          :showCount="false"
          :hasMenu="false"
          :link="false"
          class="cursor-pointer block"
          :moving="true"
        ></MoveGroup>
      </ul>
      <div class="flex justify-between space-x-4">
        <button type="button" @click="$emit('close')">Cancel</button>
        <button
          :disabled="moveTarget === null"
          type="submit"
          :class="{ 'opacity-20 cursor-not-allowed': !moveTarget }"
        >
          Move
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";

import FilterInputLocal from "@/components/FilterInputLocal";

export default {
  name: "ModalMoveGroup",
  props: ["groupToMove"],
  data() {
    return {
      name: "",
      submitting: true,
      selectedGroup: false,
      filteredGroups: false,
      moveToGroup: null,
    };
  },
  components: { FilterInputLocal, MoveGroup: () => import("../Group.vue") },
  computed: {
    ...mapState(["moveTarget"]),
    groups() {
      if (this.filteredGroups) {
        return this.filteredGroups;
      } else if (this.selectedGroup) {
        return this.$store.getters.groupChildren(this.selectedGroup._id)
          .lengths;
      } else {
        return this.$store.getters.groups;
      }
    },
  },
  mounted() {
    // this.$refs.input.focus()
  },
  methods: {
    filter(items) {
      this.filteredGroups = items;
    },
    select(group) {
      this.$store.commit("SET_MOVE_TARGET", group);
    },
    async submit() {
      this.$store.dispatch("moveGroup").then((data) => {
        if (data) {
          this.$emit("close");
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.folder-scroller {
  max-height: 70vh;
  overflow-y: scroll;
}
</style>
