import Vue from "vue";
import Vuex from "vuex";
import Fuse from "fuse.js";
import api from "../api.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentGroup: null,
    currentLabel: null,
    currentLabels: null,
    selectedLabels: [],
    groupData: null,
    filterTerm: null,
    filterGroups: null,
    filterLabels: null,
    searchTerm: null,
    zoomedOut: false,
    searchResults: null,
    searching: false,
    moveGroup: null,
    moveTarget: null,
    showSidebar: true,
  },
  mutations: {
    ADD_LABEL(state, payload) {
      state.currentLabels.unshift(payload);
    },
    DELETE_LABEL(state, payload) {
      Vue.set(
        state,
        "currentLabels",
        state.currentLabels.filter((label) => label._id !== payload.id)
      );
    },
    ADD_GROUP(state, payload) {
      state.groupData.push(payload);
    },
    DELETE_GROUP(state, payload) {
      Vue.set(
        state,
        "groups",
        state.groups.filter((group) => group.id !== payload)
      );
    },
    RENAME_GROUP(state, payload) {
      let index = state.groupData.findIndex((x) => x.id === payload.id);
      Vue.set(state.groupData, index, payload.data);
    },
    SET_MOVE_TARGET(state, payload) {
      state.moveTarget = payload;
    },
    SET_MOVE_GROUP(state, payload) {
      state.moveGroup = payload;
    },
    FETCH_GROUP(state, payload) {
      state.currentGroup = payload;
    },
    FETCH_GROUPS(state, payload) {
      state.groupData = payload;
    },
    SET_FILTER_TERM(state, payload) {
      state.filterTerm = payload;
    },
    SET_GROUP_FILTER(state, payload) {
      console.log("SETTING");
      state.filterGroups = payload;
    },
    SET_LABEL_FILTER(state, payload) {
      state.filterLabels = payload;
    },
    SET_SEARCH_TERM(state, payload) {
      state.searchTerm = payload;
    },
    FETCH_LABEL(state, payload) {
      state.currentLabel = payload;
    },
    FETCH_LABELS(state, payload) {
      state.currentLabels = payload;
    },
    UPDATE_LABEL(state, payload) {
      state.currentLabel = payload;
    },
    SET_ZOOM(state, payload) {
      state.zoomedOut = payload;
    },
    TOGGLE_ZOOM(state) {
      state.zoomedOut = !state.zoomedOut;
    },
    SET_SEARCH_RESULTS(state, payload) {
      state.searchResults = payload;
    },
    SET_SEARCHING(state, payload) {
      state.searching = payload;
    },
    SELECT_LABEL(state, payload) {
      if (state.selectedLabels.includes(payload)) {
        state.selectedLabels = state.selectedLabels.filter(
          (x) => x !== payload
        );
      } else {
        state.selectedLabels.push(payload);
      }
    },
    SET_SELECTED_LABELS(state, payload) {
      state.selectedLabels = payload;
    },
    SET_SHOW_SIDEBAR(state, payload) {
      state.showSidebar = payload;
    },
    FLUSH_SELECTED(state) {
      state.selectedLabels = [];
    },
  },
  actions: {
    setFilterTerm({ commit }, payload) {
      commit("SET_FILTER_TERM", payload);
    },
    setGroupFilter({ commit }, payload) {
      commit("SET_GROUP_FILTER", payload);
    },
    setLabelFilter({ commit }, payload) {
      commit("SET_LABEL_FILTER", payload);
    },
    setSearchTerm({ commit }, payload) {
      commit("SET_SEARCH_TERM", payload);
    },
    fetchGroup({ commit }, id) {
      return new Promise((resolve, reject) => {
        api(this, `/groups/${id}`).then(
          (data) => {
            commit("FETCH_GROUP", data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    fetchGroups({ commit }) {
      return new Promise((resolve, reject) => {
        api(this, "/groups").then(
          (data) => {
            commit("FETCH_GROUPS", data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    addGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api(this, "/groups", "POST", payload).then(
          (data) => {
            commit("ADD_GROUP", data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    renameGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api(this, `/groups/${payload.id}`, "PUT", payload.payload).then(
          (data) => {
            commit("RENAME_GROUP", {
              id: payload.id,
              data: data,
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    deleteGroup({ commit }, id) {
      return new Promise((resolve, reject) => {
        api(this, `/groups/${id}`, "DELETE").then(
          () => {
            commit("DELETE_GROUP", id);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    deleteLabel({ commit }, id) {
      return new Promise((resolve, reject) => {
        api(this, `/labels/${id}`, "DELETE").then(
          (data) => {
            commit("DELETE_LABEL", data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    fetchLabel({ commit }, id) {
      return new Promise((resolve, reject) => {
        api(this, `/labels/${id}`).then(
          (data) => {
            commit("FETCH_LABEL", data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    addLabel({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api(this, "/labels", "POST", payload).then(
          (data) => {
            commit("ADD_LABEL", data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    updateLabel({ commit }, label) {
      return new Promise((resolve, reject) => {
        api(this, `/labels/${label._id}`, "PUT", label).then(
          (data) => {
            commit("UPDATE_LABEL", data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    fetchLabels({ commit }, id) {
      return new Promise((resolve, reject) => {
        api(this, `/labels?group=${id}`).then(
          (data) => {
            commit("FETCH_LABELS", data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    search({ commit }, term) {
      commit("SET_SEARCHING", true);

      return new Promise((resolve, reject) => {
        api(this, `/search/${term}`).then(
          (data) => {
            commit("SET_SEARCH_RESULTS", data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    toggleShowSidebar({ state, commit }) {
      commit("SET_SHOW_SIDEBAR", !state.showSidebar);
    },
    moveGroup({ state, commit }) {
      if (
        state.moveGroup._id !== state.moveTarget._id &&
        state.moveGroup.parent !== state.moveTarget._id
      ) {
        let label = state.moveGroup;
        label.parent = state.moveTarget._id;

        return new Promise((resolve, reject) => {
          api(this, `/groups/${label._id}`, "PUT", label).then(
            (data) => {
              commit("UPDATE_LABEL", data);
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
    },
    stopMove({ commit }) {
      commit("SET_MOVE_GROUP", null);
      commit("SET_MOVE_TARGET", null);
    },
  },
  modules: {},
  getters: {
    group: (state) => (id) => {
      if (state.groupData) {
        return state.groupData.find((x) => x._id === id);
      } else {
        return false;
      }
    },
    groups: (state) => {
      if (state.groupData) {
        if (state.filterGroups) {
          // const fuse = new Fuse(state.groupData, {
          //   threshold: 0.2,
          //   keys: ["id", "name"],
          // });

          // return fuse.search(state.filterGroups).map((x) => x.item);

          return state.groupData.filter((x) =>
            x.name.toLowerCase().includes(state.filterGroups.toLowerCase())
          );
        } else {
          const groups = state.groupData
            .filter((x) => !Object.prototype.hasOwnProperty.call(x, "parent"))
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              } else {
                return 1;
              }
            });
          return groups;
        }
      } else {
        return false;
      }
    },
    groupChildren: (state) => (id) => {
      return state.groupData.filter((x) => x.parent === id);
    },
    labels: (state) => {
      if (state.filterLabels) {
        const fuse = new Fuse(state.currentLabels, {
          threshold: 0.6,
          keys: ["_id", "plaintext"],
          ignoreLocation: true,
        });

        return fuse.search(state.filterLabels).map((x) => x.item);
      } else {
        return state.currentLabels;
      }
    },
  },
});
