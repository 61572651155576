// Fetch API
const baseURL = process.env.VUE_APP_API_URL;

export default async (self, path, method = "GET", body = null) => {
  const accessToken = self._vm
    ? await self._vm.$auth.getTokenSilently()
    : await self.$auth.getTokenSilently();

  const params = {
    method: method,
    mode: "cors",
    headers: {
      Authorization: "Basic " + btoa(accessToken + ":" + accessToken),
      "Content-Type": "application/json",
    },
  };

  if (body) {
    params.body = JSON.stringify(body);
  }

  try {
    const res = await fetch(`${baseURL}${path}`, params);
    const json = await res.json();
    return json;
  } catch (err) {
    return err;
  }
};
